import React from 'react';

import PortraitOrientationWarning from '../components/PortraitOrientationWarning';
import { useLandscapeOrientation } from '../hooks/useLandscapeOrientation';

export default function withOrientationGuard(WrappedComponent: React.FC) {
  return function ComponentWithOrientationGuard() {
    const isOrientationLandscape = useLandscapeOrientation();

    if (!isOrientationLandscape) return <PortraitOrientationWarning />;

    return <WrappedComponent />;
  };
}
