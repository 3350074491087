import React from 'react';
import { MemoryRouter, Route } from 'react-router';

import { QuizContextProvider } from '../context/quizContext';
import AwaitQuestionsPage from '../pages/await-questions-page';
import CodePage from '../pages/code-page';
import InstructionsPage from '../pages/instructions-page';
import LoginPage from '../pages/log-in-page';
import OnboardingPage from '../pages/onboarding-page';
import PrizeDetailsPage from '../pages/prize-details-page';
import QuestionsPage from '../pages/questions-page';
import RateVideoPage from '../pages/rate-video-page';
import RegistrationPage from '../pages/registration-page';
import ScorePage from '../pages/score-page';
import VideoGatewayPage from '../pages/video-gateway-page';
import WatchVideoPage from '../pages/watch-video-page';
import WelcomePage from '../pages/welcome-page';

export default function QuizRouter(): React.ReactElement {
  return (
    <MemoryRouter>
      <QuizContextProvider>
        <Route path="/" exact component={WelcomePage} />
        <Route path="/await_questions" component={AwaitQuestionsPage} />
        <Route path="/code" component={CodePage} />
        <Route path="/instructions" component={InstructionsPage} />
        <Route path="/onboarding" component={OnboardingPage} />
        <Route path="/register" component={RegistrationPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/prize_details" component={PrizeDetailsPage} />
        <Route path="/questions" component={QuestionsPage} />
        <Route path="/rate_video" component={RateVideoPage} />
        <Route path="/video_gateway" component={VideoGatewayPage} />
        <Route path="/watch_video" component={WatchVideoPage} />
        <Route path="/welcome" component={WelcomePage} />
        <Route path="/score" component={ScorePage} />
      </QuizContextProvider>
    </MemoryRouter>
  );
}
