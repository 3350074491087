import { useQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router';

import { QUESTIONS, ANSWER_QUESTION, CREATE_ANSWERED_QUESTION_SET } from '../../api/questions';
import adPathyImage from '../../assets/adpathy.png';
import bgRedCenterLogo from '../../assets/bg_red_center_logo.png';
import offerImage from '../../assets/offer_ad_image.png';
import surveyImage from '../../assets/survey_ad_image.png';
import PortraitOrientationWarning from '../../components/PortraitOrientationWarning';
import { QuizContext } from '../../context/quizContext';
import useInterval from '../../hooks/useInterval';
import { useLandscapeOrientation } from '../../hooks/useLandscapeOrientation';
import { verifyAuthContext } from '../../libs/authentication';
import { shuffle } from '../../libs/math';
import {
  QuestionsQuery,
  QuestionBaseFragment,
  AnswerBaseFragment,
  AnswerQuestionMutation,
  AnswerQuestionMutationVariables,
  Question_Types_Enum,
  CreateAnsweredQuestionSetMutation,
  CreateAnsweredQuestionSetMutationVariables,
  User_Roles_Enum,
} from '../../types/graphql-generated';
import FourAnswers from './FourAnswers';
import TwoAnswers from './TwoAnswers';

function QuestionsPage() {
  const [video] = useContext(QuizContext);

  const history = useHistory();
  const location = useLocation();

  const isOrientationLandscape = useLandscapeOrientation();

  const [answeredQuestionSetId, setAnsweredQuestionSetId] = useState<number | null>(null);

  const authContext = verifyAuthContext();

  const [questions, setQuestions] = useState<QuestionBaseFragment[]>([]);
  const [questionTimeout, setQuestionTimeout] = useState<boolean>(false);
  const [activeQuestion, setActiveQuestion] = useState<QuestionBaseFragment | null>(null);
  const [answers, setAnswers] = useState<AnswerBaseFragment[] | null>(null);

  const { data, error } = useQuery<QuestionsQuery>(QUESTIONS);

  const [createAnsweredQuestionSet] = useMutation<
    CreateAnsweredQuestionSetMutation,
    CreateAnsweredQuestionSetMutationVariables
  >(CREATE_ANSWERED_QUESTION_SET, {
    onCompleted: ({ insert_answered_question_sets_one }) =>
      insert_answered_question_sets_one && setAnsweredQuestionSetId(insert_answered_question_sets_one.id),
  });

  const [answerQuestion] = useMutation<AnswerQuestionMutation, AnswerQuestionMutationVariables>(ANSWER_QUESTION);

  const [score, setScore] = useState(1000);

  useEffect(() => {
    if (data) {
      setQuestions([...data.videoQuestions, ...data.surveyQuestions, ...data.offerQuestions]);
    }
  }, [data]);

  useEffect(() => {
    if (questions.length) {
      setActiveQuestion(questions[0]);
    }
  }, [questions]);

  useEffect(() => {
    if (activeQuestion?.answers) {
      setAnswers(shuffle(activeQuestion.answers));
    }

    setScore(1000);
    setQuestionTimeout(true);
    setTimeout(() => setQuestionTimeout(false), 1000);
  }, [activeQuestion]);

  useInterval({
    callback: () => setScore(score - 10),
    intervalDelay: 250, // score will be reduced by 10 every 1/4 second
    shouldRun: score > 0 && !questionTimeout && !!activeQuestion && activeQuestion.type === Question_Types_Enum.VIDEO,
  });

  useEffect(() => {
    if (activeQuestion && activeQuestion.type === Question_Types_Enum.VIDEO) {
      if (score === 0) {
        handleAnswerQuestion(null);
      }
    }
  }, [score]);

  if (error) {
    return <div>Something went wrong!</div>;
  }

  if (!isOrientationLandscape) return <PortraitOrientationWarning />;

  return (
    <div className="bg-cool-gray-900 items-center justify-center flex flex-1">
      <div className="flex max-w-4xl h-112 max-h-screen w-full">
        {renderAdImage()}
        <div className="flex flex-col flex-1">
          <div
            className="bg-white font-poppins flex-col text-xl p-10 text-center text-gray-800 flex items-center justify-center"
            style={{ height: '26.5%' }}
          >
            {activeQuestion && (
              <h2 className="text-gray-600 md:text-xl sm:text-xl font-semibold italic">{activeQuestion.tagline}</h2>
            )}
            {activeQuestion && <h3 className="md:text-2xl sm:text-2xl font-semibold">{activeQuestion.text}</h3>}
          </div>
          <div
            className="relative h-full bg-center flex flex-col flex-1"
            style={{
              backgroundImage: `url(${bgRedCenterLogo})`,
              backgroundSize: '100%',
            }}
          >
            {activeQuestion && answers && (
              <Fragment>
                {activeQuestion.type !== Question_Types_Enum.OFFER && (
                  <FourAnswers handleAnswerQuestion={handleAnswerQuestion} answers={answers} />
                )}

                {activeQuestion.type === Question_Types_Enum.OFFER && (
                  <TwoAnswers handleAnswerQuestion={handleAnswerQuestion} answers={answers} />
                )}

                <div>
                  <div
                    style={{ transform: 'translateX(-50%) translateY(-50%)' }}
                    className="absolute text-3xl font-semibold text-gray-800 font-poppins top-1/2 left-1/2 z-10"
                  >
                    {score}
                  </div>
                  <svg
                    style={{ transform: 'translateX(-50%) translateY(-50%)' }}
                    className="w-40 absolute top-1/2 left-1/2 text-white"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1280.000000 640.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path
                        d="M3188 4809 c-1748 -874 -3178 -1591 -3178 -1594 0 -3 1445 -727 3210
                    -1610 l3210 -1605 3180 1590 c1749 875 3180 1592 3180 1595 0 4 -6416 3216
                    -6422 3214 -2 0 -1433 -715 -3180 -1590z"
                      />
                    </g>
                  </svg>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function renderAdImage() {
    let src: string;

    switch (activeQuestion?.type) {
      case Question_Types_Enum.VIDEO: {
        src = adPathyImage;
        break;
      }
      case Question_Types_Enum.SURVEY: {
        src = surveyImage;
        break;
      }
      case Question_Types_Enum.OFFER: {
        src = offerImage;
        break;
      }
      default: {
        src = adPathyImage;
      }
    }

    return <img className="h-full" src={src} alt="Adpathy" />;
  }

  async function handleAnswerQuestion(answer: AnswerBaseFragment | null) {
    if (!activeQuestion || !video || !authContext) {
      return;
    }

    const activeQuestionIndex = questions.findIndex(question => question.id === activeQuestion?.id);

    const isCorrectAnswerProvided = answer && answer.is_correct;

    const isTimedQuestion = activeQuestion.type === Question_Types_Enum.VIDEO;
    const calculatedScore = isTimedQuestion ? (isCorrectAnswerProvided ? score : 0) : 1000;

    if (activeQuestionIndex === 0) {
      await createAnsweredQuestionSet({
        variables: {
          score: answer && answer.is_correct ? score : 0,
          answerId: answer ? answer.id : null,
          questionId: activeQuestion.id,
          videoId: video.id,
        },
      });
    } else {
      if (answeredQuestionSetId) {
        await answerQuestion({
          variables: {
            setId: answeredQuestionSetId,
            score: calculatedScore,
            answerId: answer ? answer.id : null,
            questionId: activeQuestion.id,
            videoId: video.id,
          },
        });
      }
    }

    if (activeQuestionIndex + 1 === questions.length) {
      const { role } = authContext;

      if (role === User_Roles_Enum.UNKNOWN_USER) {
        return history.push('/register', location.state);
      } else {
        return history.push('/score', location.state);
      }
    } else {
      setActiveQuestion(questions[activeQuestionIndex + 1]);
    }
  }
}

export default QuestionsPage;
