import React, { Fragment } from 'react';

import { AnswerBaseFragment } from '../../types/graphql-generated';

type Props = {
  answers: AnswerBaseFragment[];
  handleAnswerQuestion: (answer: AnswerBaseFragment) => void;
};

export default function UntimedQuestionAnswers(props: Props) {
  const { answers, handleAnswerQuestion } = props;

  return (
    <Fragment>
      <div className="flex-1 flex border-b-4 border-white">
        <div
          onClick={() => handleAnswerQuestion(answers[0])}
          className="flex cursor-pointer items-center justify-center font-semibold text-white font-poppins text-xl border-r-4 flex-1 border-white"
        >
          {answers[0].text}
        </div>
      </div>
      <div className="flex-1 flex">
        <div
          onClick={() => handleAnswerQuestion(answers[1])}
          className="flex cursor-pointer items-center justify-center font-semibold text-white font-poppins text-xl flex-1 border-r-4 border-white"
        >
          {answers[1].text}
        </div>
      </div>
    </Fragment>
  );
}
