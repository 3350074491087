import React from 'react';

import icon from '../assets/rotate_screen.png';

function PortraitOrientationWarning() {
  return (
    <div className="bg-gray-700 flex flex-1 flex-col items-center justify-center">
      <img src={icon} className="w-28 mb-6" alt="Icon" />

      <p className="text-white">Woops...</p>
      <p className="text-white">We can't fit everything on your screen!</p>
      <p className="text-white">Please rorate to landscape.</p>
    </div>
  );
}

export default PortraitOrientationWarning;
