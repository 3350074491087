import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { VIDEO_REPORT } from '../../api/videos';
import ExportScoresButton from '../../components/ExportScoresButton';
import { calculatePercentage } from '../../libs/math';
import { VideoReportQuery, VideoReportQueryVariables, Question_Types_Enum } from '../../types/graphql-generated';

function ReportsPage() {
  const [startDatetime, setStartDatetime] = useState<Date>(
    dayjs()
      .startOf('date')
      .toDate(),
  );

  const [endDatetime, setEndDatetime] = useState<Date>(
    dayjs()
      .endOf('date')
      .toDate(),
  );

  const { data, error, loading, refetch } = useQuery<VideoReportQuery, VideoReportQueryVariables>(VIDEO_REPORT, {
    variables: { id: 1, startDatetime, endDatetime },
  });

  if (loading && !data) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;
  if (!data || !data.videos_by_pk) return <div>Empty Response...</div>;

  const { videos_by_pk: video } = data;

  const videoRatingsTotal = video.video_ratings.length;

  const video1starRatings = video.video_ratings.filter(rating => rating.value === 1).length;
  const video2starRatings = video.video_ratings.filter(rating => rating.value === 2).length;
  const video3starRatings = video.video_ratings.filter(rating => rating.value === 3).length;

  const video1starRatingsPercentage = calculatePercentage(video1starRatings, videoRatingsTotal);
  const video2starRatingsPercentage = calculatePercentage(video2starRatings, videoRatingsTotal);
  const video3starRatingsPercentage = calculatePercentage(video3starRatings, videoRatingsTotal);

  return (
    <div className="p-6 bg-cool-gray-100">
      <div className="flex items-center justify-between">
        <div className="flex p-3 items-end">
          <div className="mr-3">
            <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="startDatetime">
              Start Date/Time
            </label>
            <DatePicker
              selected={startDatetime}
              className={`form-input mt-1 relative rounded-md shadow-sm block sm:text-sm sm:leading-5 ${
                error ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300' : ''
              }`}
              onChange={(newDate: Date) => setStartDatetime(newDate)}
              name="startDatetime"
              showTimeSelect
              timeFormat="hh:mm a"
              dateFormat="MM/dd/yy hh:mm a"
              timeIntervals={60}
              timeCaption="time"
            />
          </div>
          <div className="mr-3">
            <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="startDatetime">
              End Date/Time
            </label>
            <DatePicker
              selected={endDatetime}
              className={`form-input mt-1 relative rounded-md shadow-sm block sm:text-sm sm:leading-5 ${
                error ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300' : ''
              }`}
              onChange={(newDate: Date) => setEndDatetime(newDate)}
              name="endDatetime"
              showTimeSelect
              timeFormat="hh:mm a"
              dateFormat="MM/dd/yy hh:mm a"
              timeIntervals={60}
              timeCaption="time"
            />
          </div>
        </div>

        <ExportScoresButton startDatetime={startDatetime} endDatetime={endDatetime} />
      </div>
      <div className="shadow bg-white rounded mt-2 p-3 border border-gray-50">
        <p className="font-medium text-gray-700">GENERAL</p>
        <p className="text-sm text-gray-800 leading-6">Video views: {video.video_views_aggregate.aggregate?.count}</p>
        <p className="text-sm text-gray-800 leading-6">
          Questions answered: {video.totalQuestionsAnswered.aggregate?.count}
        </p>
        <p className="text-sm text-gray-800 leading-6">
          Questions answered correctly: {video.totalCorrectQuestionsAnswered.aggregate?.count}
        </p>
        <p className="text-sm text-gray-800 leading-6">
          Questions unanswered/skipped: {video.totalUnanswered.aggregate?.count}
        </p>

        <p className="text-sm text-gray-800 leading-6">
          Percentage of questions answered correctly:{' '}
          {calculatePercentage(
            video.totalCorrectQuestionsAnswered!.aggregate!.count!,
            video.totalQuestionsAnswered!.aggregate!.count!,
          )}
          %
        </p>
      </div>
      <div className="shadow bg-white rounded mt-2 p-3 border border-gray-50">
        <p className="font-medium text-gray-700">RATINGS</p>
        <p className="text-sm text-gray-800 leading-6">Video ratings: {video.video_ratings.length}</p>
        <p className="text-sm text-gray-800 leading-6">
          1 star ratings: {video1starRatings} ({video1starRatingsPercentage}%)
        </p>
        <p className="text-sm text-gray-800 leading-6">
          2 star ratings: {video2starRatings} ({video2starRatingsPercentage}%)
        </p>
        <p className="text-sm text-gray-800 leading-6">
          3 star ratings: {video3starRatings} ({video3starRatingsPercentage}%)
        </p>
      </div>

      <div className="mt-3">
        {video.questions
          .filter(question => question.type === Question_Types_Enum.VIDEO)
          .map(question => {
            return (
              <div className="shadow bg-white rounded mt-2 p-3 border border-gray-50" key={question.id}>
                <p className="font-medium text-sm text-gray-800">QUESTION: {question.text}</p>
                <p className="text-sm text-gray-800 leading-6">
                  Answered total: {question.totalAnsweredCount.aggregate?.count} time(s)
                </p>
                <p className="text-sm text-gray-800 leading-6">
                  Answered correctly: {question.correctAnsweredCount.aggregate?.count} time(s)
                </p>
                <p className="text-sm text-gray-800 leading-6">
                  Skipped: {question.totalUnansweredCount.aggregate?.count} time(s)
                </p>
                <p className="text-sm text-gray-800 leading-6">
                  Percentage of correct answers:{' '}
                  {calculatePercentage(
                    question.correctAnsweredCount.aggregate!.count!,
                    question.totalAnsweredCount.aggregate!.count!,
                  )}
                  %
                </p>
                <div className="mt-3">
                  <p className="font-medium text-gray-700">Answers:</p>
                  {question.answers.map(answer => {
                    return (
                      <div className="pl-6" key={answer.id}>
                        <p className="text-sm text-gray-800 leading-6">
                          {answer.text} {answer.is_correct ? '(Correct):' : ':'}{' '}
                          {calculatePercentage(
                            answer.totalAnsweredCount.aggregate!.count!,
                            question.totalAnsweredCount.aggregate!.count!,
                          )}
                          %
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>

      <div className="mt-3">
        {video.questions
          .filter(question => question.type === Question_Types_Enum.SURVEY)
          .map(question => {
            return (
              <div className="shadow bg-white rounded mt-2 p-3 border border-gray-50" key={question.id}>
                <p className="font-medium text-gray-700">SURVEY: {question.text}</p>
                <div className="mt-3">
                  <p className="text-sm text-gray-800 leading-6">
                    Answered {question.totalAnsweredCount.aggregate?.count} total times
                  </p>

                  <p className="font-medium text-gray-700">Answers:</p>
                  {question.answers.map(answer => {
                    return (
                      <div className="pl-6" key={answer.id}>
                        <p className="text-sm text-gray-800 leading-6">
                          {answer.text}{' '}
                          {calculatePercentage(
                            answer.totalAnsweredCount.aggregate!.count!,
                            question.totalAnsweredCount.aggregate!.count!,
                          )}
                          %
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      <div className="mt-3">
        {video.questions
          .filter(question => question.type === Question_Types_Enum.OFFER)
          .map(question => {
            return (
              <div className="shadow bg-white rounded mt-2 p-3 border border-gray-50" key={question.id}>
                <p className="font-medium text-gray-700">OFFER: {question.text}</p>
                <div className="mt-3">
                  <p className="text-sm text-gray-800 leading-6">
                    Answered {question.totalAnsweredCount.aggregate?.count} total times
                  </p>

                  <p className="font-medium text-gray-700">Answers:</p>
                  {question.answers.map(answer => {
                    return (
                      <div className="pl-6" key={answer.id}>
                        <p className="text-sm text-gray-800 leading-6">
                          {answer.text}{' '}
                          {calculatePercentage(
                            answer.totalAnsweredCount.aggregate!.count!,
                            question.totalAnsweredCount.aggregate!.count!,
                          )}
                          %
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );

  async function handleDateFilter() {
    await refetch({ id: 1, startDatetime, endDatetime });
  }
}

export default ReportsPage;
