import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';

import { verifyAuthContext } from '../libs/authentication';

const authLink = setContext(async () => {
  const authContext = verifyAuthContext();

  if (!authContext) {
    return {
      'x-hasura-role': 'anonymous',
    };
  }

  return {
    headers: {
      Authorization: `Bearer ${authContext.accessToken}`,
      'x-hasura-role': authContext.role,
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const link = ApolloLink.from([authLink, apolloLogger, httpLink]);

const cache = new InMemoryCache();

export const client = new ApolloClient({ cache, link });
