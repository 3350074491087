import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AdminLoginPage from '../pages/admin-login-page';
import ReportsPage from '../pages/reports-page';
import { AdminRoute, AdminAuthRoute } from './customRoutes';
import QuizRouter from './QuizRouter';

function MainRouter(): React.ReactElement {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/quiz" />} />
      <Route exact path="/quiz" component={QuizRouter} />

      <AdminAuthRoute exact path="/login" component={AdminLoginPage} />
      <AdminRoute exact path="/reports" component={ReportsPage} />
    </Switch>
  );
}

export default MainRouter;
