import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import Title from '../../components/Title';
import { EXPECTED_QUESTIONS_NUM } from '../../config/constants';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import Layout from '../../layouts';
import { verifyAuthContext } from '../../libs/authentication';
import { CreateUnknownUserMutation } from '../../types/graphql-generated';

function InstructionsPage() {
  const authContext = verifyAuthContext();
  const [createUnknownUser] = useMutation<CreateUnknownUserMutation>(CREATE_UNKNOWN_USER);

  const history = useHistory();

  React.useEffect(() => {
    async function createUser() {
      try {
        if (!authContext) {
          const mutationResult = await createUnknownUser();

          if (mutationResult.data) {
            const { accessToken } = mutationResult.data.createUnknownUser;

            localStorage.setItem('ACCESS_TOKEN', accessToken);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    createUser();
  }, []);

  return (
    <Layout>
      <Title>Game Details</Title>
      <div className="text-gray-800 text-xl md:text-3xl my-4">
        <ul className="list-disc">
          <li>
            <b>Pay Close Attention to the video.</b>
          </li>
          <li>
            <b>{EXPECTED_QUESTIONS_NUM}</b> Questions – Max. <b style={{ color: '#850029' }}>1,000</b> pts. each.
          </li>
          <li>Faster correct answers score more.</li>
        </ul>
      </div>

      <Button title="Play" handleClick={navigateToVideo} />
    </Layout>
  );

  function navigateToVideo() {
    history.push('/watch_video');
  }
}

const CREATE_UNKNOWN_USER = gql`
  mutation createUnknownUser {
    createUnknownUser {
      __typename
      accessToken
    }
  }
`;

export default withOrientationGuard(InstructionsPage);
