import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import tw from 'twin.macro';
import * as Yup from 'yup';

import Button from '../../components/Button';
import Layout from '../../layouts';
import { RegisterMutation, RegisterMutationVariables } from '../../types/graphql-generated';

type FormValues = {
  firstName: string;
  email: string;
  zipCode: string;
  tos: boolean;
};

function RegistrationPage() {
  const history = useHistory();
  const location = useLocation();

  const { handleSubmit, touched, errors, setFieldTouched, values, setFieldValue, isValid } = useFormik<any>({
    initialValues: {
      firstName: '',
      email: '',
      zipCode: '',
      tos: false,
    },
    isInitialValid: false,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(REGISTER);

  return (
    <Layout flexibleHeight>
      <StyledContentContainer>
        <h3 className="text-gray-800 lg:mt-10 font-poppins font-semibold text-2xl lg:text-4xl mt-0">How did you do?</h3>
        <p className="text-gray-800 text-center text-lg sm:text-lg font-poppins leading-8 px-14 lg:mb-6 mb-1">
          To see your score and to be eligible to win, let us know who you are:
        </p>

        <form className="flex flex-col items-center" onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row lg:mb-4">
            <div className="relative mb-2 md:mr-2">
              <input
                value={values.firstName}
                onChange={event => setFieldValue('firstName', event.target.value)}
                onBlur={() => setFieldTouched('firstName')}
                id="firstName"
                className={`form-input font-poppins rounded-md block w-64 md:w-32 ${
                  touched.firstName && errors.firstName ? 'border-yellow-400' : ''
                }`}
                placeholder="First Name"
              />

              {touched.firstName && errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
            </div>

            <div className="relative mb-2 md:mr-2">
              <input
                value={values.email}
                onBlur={() => {
                  setFieldValue('email', values.email.toLowerCase().trim());
                  setFieldTouched('email');
                }}
                onChange={event => setFieldValue('email', event.target.value)}
                id="email"
                className={`form-input font-poppins rounded-md block w-64 ${
                  touched.email && errors.email ? 'border-yellow-400' : ''
                }`}
                placeholder="Email"
              />

              {touched.email && errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </div>

            <div className="relative mb-2">
              <input
                value={values.zipCode}
                onChange={event => setFieldValue('zipCode', event.target.value)}
                onBlur={() => setFieldTouched('zipCode')}
                id="zipCode"
                className={`form-input font-poppins rounded-md block w-64 md:w-32 ${
                  touched.zipCode && errors.zipCode ? 'border-yellow-400' : ''
                }`}
                placeholder="Zip Code"
              />

              {touched.zipCode && errors.zipCode && <ErrorMessage>{errors.zipCode}</ErrorMessage>}
            </div>
          </div>

          <div className="grid gap-4 text-gray-800 text-sm lg:mb-4 w-96" style={{ gridTemplateColumns: '1rem 1fr' }}>
            <Checkbox
              id="tos"
              name="tos"
              type="checkbox"
              onChange={event => setFieldValue('tos', event.target.checked)}
            />

            <label htmlFor="tos">
              I have read and accept the Official Rules, and warrant that I am eligible to participate{' '}
              <span className={touched.tos && errors.tos ? 'font-bold text-yellow-400' : ''}>(Required)</span>
            </label>
          </div>

          <Button type="submit" title="ENTER" />
        </form>

        <div className="flex flex-col md:flex-row justify-between text-gray-800 items-center md:items-end text-xs w-full my-8 md:mt-0 md:mb-0 px-4">
          <div className="mb-8 md:mb-0">
            <p>Sponsored by and paid for by our sponsor.</p>
            <p>
              <b>Sponsor Name P. O. Box 1234 Anycity, FL 12345</b>
            </p>
          </div>

          <div className="flex flex-col items-center text-center md:text-left">
            <a
              href="https://react.net"
              target="_blank"
              rel="noopener noreferrer"
              className="block font-bold text-sm underline mb-2"
            >
              OFFICIAL RULES
            </a>
            <p>
              © 2023 US Patents & Patents Pending <br /> <b>React, LLC</b> <a href="https://react.net">www.react.net</a>
            </p>
          </div>
        </div>
      </StyledContentContainer>
    </Layout>
  );

  async function onSubmit(formValues: FormValues, formikHelpers: any) {
    const { tos, ...variables } = formValues;
    const { setFieldError } = formikHelpers;

    try {
      const mutationResult = await register({ variables });

      if (mutationResult.data) {
        const { accessToken } = mutationResult.data.register;

        localStorage.setItem('ACCESS_TOKEN', accessToken);

        history.push('/score', location.state);
      }
    } catch ({ graphQLErrors }) {
      if (graphQLErrors.length) {
        const { message } = graphQLErrors[0];

        if (message.includes('users_email_key')) {
          setFieldError('email', 'A user with this email already exists.');
        }
      }
    }
  }
}

const StyledContentContainer = styled.div`
  ${tw`flex bg-cover overflow-auto max-w-4xl max-h-screen w-full flex-col items-center`}
`;

const Checkbox = styled.input`
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      -webkit-transform: scale(1.4, 1.4);
      margin-top: 15px;
    }
  }
`;

const ErrorMessage = styled.p`
  ${tw`hidden md:block absolute text-xs mt-1 font-bold font-poppins text-yellow-400`}
`;

const REGISTER = gql`
  mutation register($email: String!, $firstName: String!, $zipCode: String) {
    register(email: $email, firstName: $firstName, zipCode: $zipCode) {
      accessToken
    }
  }
`;

export default RegistrationPage;

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required.'),
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  zipCode: Yup.string().required('Required.'),
  tos: Yup.bool().oneOf([true], 'You need to accept the official rules'),
});
