import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import welcomeFullImage from '../../assets/welcome_page_full.png';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import Layout from '../../layouts';
import { resetAuthContext } from '../../libs/authentication';

function WelcomePage() {
  const history = useHistory();

  useEffect(() => {
    resetAuthContext();
  }, []);

  return (
    <Layout>
      <img src={welcomeFullImage} onClick={() => navigateToInstructionPage()} alt="Welcome" />
    </Layout>
  );

  function navigateToInstructionPage() {
    history.push('/instructions');
  }
}

export default withOrientationGuard(WelcomePage);
