import React, { PropsWithChildren, Dispatch, SetStateAction } from 'react';

import { VideoBaseFragment } from '../types/graphql-generated';

const QuizContext = React.createContext<[VideoBaseFragment | null, Dispatch<SetStateAction<VideoBaseFragment | null>>]>(
  [null, () => {}],
);

function QuizContextProvider(props: PropsWithChildren<{}>) {
  const [video, setVideo] = React.useState<VideoBaseFragment | null>(null);

  return <QuizContext.Provider value={[video, setVideo]}>{props.children}</QuizContext.Provider>;
}

export { QuizContext, QuizContextProvider };
