import React from 'react';

type Props = {
  handleClick?: () => void;
  title: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

export default function ButtonSilver(props: Props) {
  return (
    <button
      type={props.type || 'button'}
      disabled={props.disabled}
      onClick={props.handleClick}
      style={{
        minWidth: '8rem',
        background: '-webkit-gradient(linear, 0% 50%, 0% 100%, from(#ffffff), to(#9f9f9f))',
        textShadow: '0 10px 15px rgba(0,0,0, 0.3)',
        fontSize: '20px',
        padding: '6px 40px',
        marginTop: '30px'
      }}
      className="items-center rectangle-full mt-4 px-6 text-3xl font-bold text-black transition ease-in-out duration-150"
    >
      {props.title}
    </button>
  );
}