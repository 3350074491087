import { useEffect, useState } from 'react';

export const useLandscapeOrientation = () => {
  const orientationMediaQuery = window.matchMedia('(orientation: landscape)');
  const isMobile = !!navigator.userAgent.toLowerCase().match(/mobile/i);

  const [isOrientationLandscape, setIsOrientationLandscape] = useState<boolean>(orientationMediaQuery.matches);

  useEffect(() => {
    function orientationListener(orientationChangeEvent: MediaQueryListEvent) {
      const isOrientationChangedToLandscape = orientationChangeEvent.matches;

      setIsOrientationLandscape(isOrientationChangedToLandscape);
    }

    orientationMediaQuery.addListener(orientationListener);

    return () => orientationMediaQuery.removeListener(orientationListener);
  }, []);

  return isOrientationLandscape || !isMobile;
};
