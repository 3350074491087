import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { verifyAuthContext } from '../libs/authentication';
import AdminLoginPage from '../pages/admin-login-page';
import { User_Roles_Enum } from '../types/graphql-generated';

export function ProtectedRoute({ component, ...rest }: RouteProps): React.ReactElement {
  const authContext = verifyAuthContext();

  const isAuthenticated = !!authContext && authContext.role !== User_Roles_Enum.UNKNOWN_USER;

  return (
    <Route
      {...rest}
      render={props =>
        !!isAuthenticated && component ? React.createElement(component, props) : <Redirect to="/onboarding" />
      }
    />
  );
}

export function AdminAuthRoute({ component, ...rest }: RouteProps): React.ReactElement {
  const authContext = verifyAuthContext();

  const isUnauthenticated = !authContext || authContext.role !== User_Roles_Enum.SUPER_USER;

  return (
    <Route
      {...rest}
      render={props =>
        isUnauthenticated && component ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );
}

export function AdminRoute({ component, ...rest }: RouteProps): React.ReactElement {
  const authContext = verifyAuthContext();

  const isAdmin = !!authContext && authContext.role === User_Roles_Enum.SUPER_USER;

  if (!isAdmin) {
    return <Route component={AdminLoginPage} />;
  }

  return <Route {...rest} render={props => component && React.createElement(component, props)} />;
}
