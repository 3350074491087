import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import { POINTS_TO_WIN_PRIZES } from '../../config/constants';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import useInterval from '../../hooks/useInterval';
import Layout from '../../layouts';

function PrizeDetailsPage() {
  const [timer, setTimer] = React.useState(10);

  const history = useHistory();

  React.useEffect(() => {
    if (timer === 0) {
      history.push(`/watch_video`);
    }
  }, [timer]);

  useInterval({
    callback: () => setTimer(timer - 1),
    intervalDelay: 1000,
    shouldRun: timer > 0,
  });

  return (
    <Layout>
      <Title>Game</Title>
      <Subtitle>NOW LOADING...</Subtitle>
      <p className="text-gray-800 text-center text-2xl font-poppins leading-8 mt-4 mb-1 px-6 lg:px-24">
        5 Weekly Players with {POINTS_TO_WIN_PRIZES}+ points will win big prizes.
      </p>

      <p className="text-yellow-400 font-bold font-poppins text-3xl lg:text-6xl">{timer}</p>
      <Button title="NEXT" handleClick={handleNavigation} />
    </Layout>
  );

  function handleNavigation() {
    history.push('/watch_video');
  }
}

export default withOrientationGuard(PrizeDetailsPage);
