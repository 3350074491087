import gql from 'graphql-tag';

import { AnswerBaseFragment } from './answers';

// Fragments
export const QuestionBaseFragment = gql`
  fragment QuestionBaseFragment on questions {
    id
    tagline
    text
    type
    answers {
      ...AnswerBaseFragment
    }
  }
  ${AnswerBaseFragment}
`;

// Queries

export const QUESTIONS = gql`
  query questions {
    videoQuestions: questions(where: { type: { _eq: VIDEO } }) {
      ...QuestionBaseFragment
    }
    surveyQuestions: questions(where: { type: { _eq: SURVEY } }) {
      ...QuestionBaseFragment
    }
    offerQuestions: questions(where: { type: { _eq: OFFER } }) {
      ...QuestionBaseFragment
    }
  }
  ${QuestionBaseFragment}
`;

// Mutations

export const ANSWER_QUESTION = gql`
  mutation answerQuestion($setId: Int!, $answerId: Int, $videoId: Int!, $questionId: Int!, $score: Int!) {
    insert_answered_questions_one(
      object: { set_id: $setId, answer_id: $answerId, video_id: $videoId, question_id: $questionId, score: $score }
    ) {
      id
    }
  }
`;

export const CREATE_ANSWERED_QUESTION_SET = gql`
  mutation createAnsweredQuestionSet($answerId: Int, $videoId: Int!, $questionId: Int!, $score: Int!) {
    insert_answered_question_sets_one(
      object: {
        video_id: $videoId
        answered_questions: {
          data: [{ answer_id: $answerId, video_id: $videoId, question_id: $questionId, score: $score }]
        }
      }
    ) {
      id
    }
  }
`;
