import styled from '@emotion/styled';
import React from 'react';
import tw from 'twin.macro';

type Props = {
  children: React.ReactNode;
};

export default function Title(props: Props) {
  return <StyledTitle>{props.children}</StyledTitle>;
}

const StyledTitle = styled.div`
  ${tw`
    font-poppins
    font-semibold
    mt-3
    text-3xl
    text-center
    lg:text-5xl
    text-gray-900
  `}
`;
