import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import Button from '../../components/Button';
import Title from '../../components/Title';
import { QuizContext } from '../../context/quizContext';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import useInterval from '../../hooks/useInterval';
import Layout from '../../layouts';
import { RateVideoMutation, RateVideoMutationVariables } from '../../types/graphql-generated';
import replayRateTitleBg from '../../assets/replay_rate_title_bg.png';
import bgCircleFullImage from '../../assets/rate_video_bg_full.png';
import { RATE_VIDEO_POINTS } from '../../config/constants';

function RateVideoPage() {
  const [timer, setTimer] = React.useState(10);
  const [rating, setRating] = React.useState<number>(0);

  const [video] = useContext(QuizContext);

  const history = useHistory();

  React.useEffect(() => {
    if (timer === 0) {
      history.push(`/await_questions`);
    }
  }, [timer]);

  useInterval({
    callback: () => setTimer(timer - 1),
    intervalDelay: 1000,
    shouldRun: timer > 0,
  });

  const [rateVideo] = useMutation<RateVideoMutation, RateVideoMutationVariables>(RATE_VIDEO);

  return (
    <Layout>
    <div className="text-black font-bold w-full h-full text-center" style={{  
      backgroundImage: "url(" + bgCircleFullImage + ")",
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
      <div style={{
        backgroundImage: "url(" + replayRateTitleBg + ")",
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '99px',
        fontSize: '28px',
        lineHeight: '99px',
        marginTop: '40px'
      }}>
        Replay <span style={{color: '#e8402f'}}>the Ad or Tap a Star to</span> Rate It
      </div>
      <div className="flex w-full" style={{marginTop: '10%'}}>
        <div className="w-1/3 text-center">
          <Button
            title="Replay Ad"
            handleClick={handleNavigation}
          />
        </div>
        <div className="w-1/3 text-center">
          <p className="text-white font-bold font-poppins" style={{
              fontSize: '140px',
              lineHeight: '99px',
              textShadow: '0 0 2px white',
              letterSpacing: '6px'
            }}>:{((timer < 10) ? '0' : '') + timer}</p>
        </div>
        <div className="w-1/3 text-center">
          <div className="flex justify-center my-3">
            <div className="cursor-pointer" onClick={() => handleRateVideo(1)}>
              <Star filled={rating >= 1} />
            </div>
            <div className="cursor-pointer" onClick={() => handleRateVideo(2)}>
              <Star filled={rating >= 2} />
            </div>
            <div className="cursor-pointer" onClick={() => handleRateVideo(3)}>
              <Star filled={rating === 3} />
            </div>
          </div>
          
          <div className="font-medium items-center text-white" style={{
            border: '1px solid rgba(255, 255, 255, .5)',
            width: '80%',
            margin: '2vh auto 0 auto',
            letterSpacing: '1px',
            padding: '5px'
          }}>
            +{RATE_VIDEO_POINTS}PT. FOR REACTING
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );

  function handleNavigation() {
    history.push('/watch_video');
  }

  async function handleRateVideo(value: number) {
    setRating(value);

    if (!video) {
      return;
    }

    try {
      const mutationResult = await rateVideo({ variables: { value, videoId: video.id } });

      if (mutationResult.data) {
        history.push(`/await_questions`, { videoRated: true });
      }
    } catch (err) {
      console.log(err);
    }
  }
}

function Star({ filled }: { filled: boolean }) {
  if (filled) {
    return (
      <svg
        className="w-10 lg:w-14 mr-2 ml-2 text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        style={{stroke: '#e8402f', strokeWidth: '1px', filter: 'drop-shadow(0 0 2px rgb(0 0 0 / 0.2))'}}
      >
        <path
          fill="currentColor"
          d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className="w-10 lg:w-14 mr-2 ml-2 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 24 24"
        style={{filter: 'drop-shadow(0 0 2px rgb(0 0 0 / 0.2))'}}
      >
        <path
          fill="currentColor"
          d="M15.668 8.626l8.332 1.159-6.065 5.874 1.48 8.341-7.416-3.997-7.416 3.997 1.481-8.341-6.064-5.874 8.331-1.159 3.668-7.626 3.669 7.626zm-6.67.925l-6.818.948 4.963 4.807-1.212 6.825 6.068-3.271 6.069 3.271-1.212-6.826 4.964-4.806-6.819-.948-3.002-6.241-3.001 6.241z"
        />
      </svg>
    );
  }
}

const RATE_VIDEO = gql`
  mutation rateVideo($value: Int!, $videoId: Int!) {
    insert_video_ratings_one(object: { value: $value, video_id: $videoId }) {
      id
    }
  }
`;

export default withOrientationGuard(RateVideoPage);
